/* 오버레이 애니메이션 */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(192, 192, 192, 0.3);
}

.overlay-enter {
    opacity: 0; /* 초기 상태 */
}

.overlay-enter-active {
    opacity: 1; /* 보여짐 */
    transition: opacity 100ms ease-in-out; /* 페이드 효과 */
}

.overlay-exit {
    opacity: 1; /* 종료 상태 */
}

.overlay-exit-active {
    opacity: 0; /* 사라짐 */
    transition: opacity 100ms ease-in-out; /* 페이드 효과 */
}

/* 모달 애니메이션 */
.modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 초기 위치: 화면 위 */
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.modal-enter {
    opacity: 0;
    transform: translate(-50%, -60%); /* 위에서 시작 */
}

.modal-enter-active {
    opacity: 1;
    transform: translate(-50%, -50%); /* 중앙으로 이동 */
    transition: opacity 160ms ease-in-out, transform 160ms ease-in-out; /* 페이드와 이동 */
}

.modal-exit {
    opacity: 1;
    transform: translate(-50%, -50%); /* 중앙에서 시작 */
}

.modal-exit-active {
    opacity: 0;
    transform: translate(-50%, -60%); /* 위로 사라짐 */
    transition: opacity 160ms ease-in-out, transform 160ms ease-in-out; /* 페이드와 이동 */
}

/* 닫기 버튼 */
.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}