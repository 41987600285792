@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    background: #F8F8F8;
    font-family: pd-r, sans-serif;
}

@font-face {
    font-family: "pd-black";
    src: url("./resources/fonts/pretendard/Pretendard-Black.otf") format("opentype");
}

@font-face {
    font-family: "pd-b";
    src: url("./resources/fonts/pretendard/Pretendard-Bold.otf") format("opentype");
}

@font-face {
    font-family: "pd-eb";
    src: url("./resources/fonts/pretendard/Pretendard-ExtraBold.otf") format("opentype");
}

@font-face {
    font-family: "pd-el";
    src: url("./resources/fonts/pretendard/Pretendard-ExtraLight.otf") format("opentype");
}

@font-face {
    font-family: "pd-l";
    src: url("./resources/fonts/pretendard/Pretendard-Light.otf") format("opentype");
}

@font-face {
    font-family: "pd-m";
    src: url("./resources/fonts/pretendard/Pretendard-Medium.otf") format("opentype");
}

@font-face {
    font-family: "pd-r";
    src: url("./resources/fonts/pretendard/Pretendard-Regular.otf") format("opentype");
}

@font-face {
    font-family: "pd-sb";
    src: url("./resources/fonts/pretendard/Pretendard-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "pd-t";
    src: url("./resources/fonts/pretendard/Pretendard-Thin.otf") format("opentype");
}

@page {
    size: A4; /* A4 사이즈 지정 */
    margin: 0; /* 페이지 여백 제거 */
}

.print-container {
    display: flex;
    flex-wrap: wrap; /* 카드가 넘치면 다음 행으로 배치 */
    gap: 62px 59px; /* 카드 간 간격 */
    justify-content: flex-start; /* 카드 간의 균일한 간격 */
    align-content: flex-start; /* 카드가 위쪽부터 배치 */
    width: 210mm; /* A4 너비 */
    height: 297mm; /* A4 높이 */
    padding: 8mm 10mm; /* 페이지 여백 */
    box-sizing: border-box;
    background-image: url('./resources/images/qr_print_cut.png'); /* 배경 이미지 */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    page-break-after: always; /* 페이지 나누기 */
}

.print-card {
    width: 200px; /* 카드 가로 크기 고정 */
    height: 83mm; /* 계산된 카드 세로 크기 */
    padding: 10px;
    page-break-inside: avoid; /* 카드가 페이지를 넘지 않도록 설정 */
}
