.toggle-button {
    transition: 0.2s ease-out;
    @apply h-[68px] rounded-t-[34px] rounded-b-[34px] border-[0.5px] border-t-[#dadada] border-x-[#dadada] border-b-[#dadada] shadow-[0_2px_7px_0_rgba(0,0,0,0.10)] bg-white flex justify-center items-center text-[24px] text-[#355BDF] font-[pd-b] relative cursor-pointer
}

.toggle-button.open {
    @apply border-b-[#ffffff] rounded-b-[0]
}

.dropdown {
    position: absolute;
    top: 67px;
    left: 0;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    z-index: 1;
    @apply border-x-[0.5px] border-b-[0.5px] border-x-transparent border-b-transparent rounded-b-[34px]
}

.dropdown.open {
    @apply border-x-[#dadada] border-b-[#dadada]
}

.dropdown-list {
    overflow-y: auto;
    @apply bg-white rounded-b-[34px] py-[4px]
}

.dropdown-item {
    @apply w-full h-[68px] bg-white flex justify-center items-center cursor-pointer relative px-[8px]
}
